import { getURLParameterByName } from 'utility/pages';
import { isMobileBrowser} from 'utility/devices';
import { transport } from 'service/transport';

const jwtUrlParameter = 'auth_token';
const grTrackingParameter = 'is_gr_tracking';
const arTrackingParameter = 'ar';

function isGRClickTracking() {
  return getURLParameterByName(grTrackingParameter) === 'true';
}

function isARClickTracking() {
  return getURLParameterByName(arTrackingParameter) === 'true';
}

function trackClick(embed, articleId) {
  const authToken = getURLParameterByName(jwtUrlParameter);

  if (!authToken) return;

  const payload = {
    path: '/api/v2/answer_bot/article_click_tracking',
    queryParams: {
      'auth_token': authToken,
      source: 'embed',
      mobile: isMobileBrowser(),
      'article_id': articleId
    },
    method: 'post',
    callbacks: {
      done: () => {},
      fail: () => {
        embed.instance.hide();
      }
    }
  };

  transport.automaticAnswersApiRequest(payload);
}

export const articleClickTracker = {
  isGRClickTracking,
  trackClick,
  isARClickTracking
};
