export function cssTimeToMs(str) {
  const time = parseInt(str, 10) || 0;
  const pattern = /\d+s$/;

  return pattern.test(str) ? (time * 1000) : time;
}

export function cappedTimeoutCall(callback, delay = 50, repetitionCap = 1) {
  // Do not implement using setInterval
  // There were past issues with IE10 when a function's closure variables
  // were getting snapshotted. The evaluated result would always be the
  // same, potentially generating an infinite loop.
  let repCount = 0;
  const recurseFn = () => {
    repCount = repCount + 1;

    if (callback() || repCount >= repetitionCap) return;

    setTimeout(() => recurseFn(), delay);
  };

  recurseFn();
}

export const toSingleSpaces = (string) => {
  return string.trim().replace(/\n {1,}/g, ' ');
};
