import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@zendeskgarden/react-theming';

export class EmbedWrapper extends Component {
  static propTypes = {
    baseCSS: PropTypes.string,
    childFn: PropTypes.func,
    childParams: PropTypes.object,
    children: PropTypes.object,
    fullscreen: PropTypes.bool,
    handleBackClick: PropTypes.func,
    handleCloseClick: PropTypes.func,
    hideCloseButton: PropTypes.bool,
    useBackButton: PropTypes.bool
  };

  static defaultProps = {
    baseCSS: '',
    childFn: undefined,
    childParams: {},
    children: undefined,
    fullscreen: false,
    handleBackClick: () => {},
    handleCloseClick: () => {},
    hideCloseButton: false,
    useBackButton: false
  };

  constructor(props, context) {
    super(props, context);

    this.state = { css: '' };
  }

  render = () => {
    const styleTag = <style dangerouslySetInnerHTML={{ __html: this.state.css }} />;
    const css = <style dangerouslySetInnerHTML={{ __html: this.props.baseCSS }} />;

    const newChild = this.props.childFn(this.props.childParams);

    return (
      <ThemeProvider>
        {css}
        {styleTag}
        <div
          id="answer-bot-embed"
          ref={el => {
            this.embed = el;
          }}
        >
          {newChild}
        </div>
      </ThemeProvider>
    );
  }
}
