import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class Button extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    fullscreen: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    onTouchStartDisabled: PropTypes.bool,
    style: PropTypes.object,
    type: PropTypes.string,
    testId: PropTypes.string,
    primary: PropTypes.bool
  };

  static defaultProps = {
    className: '',
    disabled: false,
    fullscreen: false,
    label: '',
    onClick: () => {},
    onTouchStartDisabled: false,
    style: null,
    type: 'submit',
    primary: true
  };

  render = () => {
    const { testId } = this.props ;
    const buttonStyles = 'c-btn c-btn--medium Anim-color u-textNoWrap';
    const filledStyles = 'c-btn--primary u-borderTransparent u-userBackgroundColor';
    const primaryClasses = this.props.primary ? filledStyles : 'u-userBorderColor';
    const mobileClasses = this.props.fullscreen ? 'u-sizeFull u-textSizeBaseMobile u-textTruncate' : '';
    const allowedTypes = /^(submit|button)$/i;
    const type = allowedTypes.test(this.props.type)
      ? this.props.type
      : 'button';

    return (
      <input data-testid={testId}
        type={type}
        value={this.props.label}
        onClick={this.props.onClick}
        onTouchStart={this.props.onTouchStartDisabled ? null : this.props.onClick}
        disabled={this.props.disabled}
        style={this.props.style}
        className={`${buttonStyles} ${this.props.className} ${primaryClasses} ${mobileClasses}`} />
    );
  }
}
