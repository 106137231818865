import * as log from 'loglevel';
import { win } from 'utility/globals';
import { isDev } from './isDev';

win.log = log;

const logLevel = isDev() ? log.levels.TRACE : log.levels.ERROR;

log.setLevel(logLevel);

export { log };
