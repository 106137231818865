import Color from 'color';

const defaultColor = '#78A300';

export function generateUserCSS(color = defaultColor) {
  const highlightColor = generateHighlightColor(color);
  const constrastColor = generateContrastColor(color);

  return (`
    .u-userBackgroundColor:not([disabled]) {
      background-color: ${color} !important;
    }
    .u-userBackgroundColor:not([disabled]):hover,
    .u-userBackgroundColor:not([disabled]):active,
    .u-userBackgroundColor:not([disabled]):focus {
      background-color: ${highlightColor} !important;
    }
    .u-userBorderColor:not([disabled]) {
      color: ${color} !important;
      background-color: transparent !important;
      border-color: ${color} !important;
    }
    .u-userBorderColor:not([disabled]):hover,
    .u-userBorderColor:not([disabled]):active,
    .u-userBorderColor:not([disabled]):focus {
      color: ${constrastColor} !important;
      background-color: ${highlightColor} !important;
      border-color: ${highlightColor} !important;
    }
  `);
}

function generateContrastColor(colorStr) {
  try {
    const color = Color(colorStr);

    return (color.luminosity() <= 0.35)
      ? 'white'
      : 'black';
  } catch (e) {
    return;
  }
}

function generateHighlightColor(colorStr) {
  try {
    const color = Color(colorStr);

    return (color.luminosity() > 0.15)
      ? color.darken(0.1).rgbString()
      : color.lighten(0.15).rgbString();
  } catch (e) {
    return;
  }
}
