import { log } from 'utility/log';
import { boot } from './boot';

export function init(locale) {
  log.debug('init', locale);

  try {
    boot.start(locale);
  } catch (err) {
    log.error(err);
  }
}
