import { i18n } from 'service/i18n';
import { log } from 'utility/log';
import { automaticAnswers } from 'embed/automaticAnswers/automaticAnswers';

const start = (locale) => {
  i18n.setLocale(locale);

  log.debug('start v1');

  automaticAnswers.create('automaticAnswers');
  automaticAnswers.render();
  automaticAnswers.postRender();
};

export const boot = { start };
