import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export class Container extends Component {
  static propTypes = {
    card: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    fullscreen: PropTypes.bool,
    onClick: PropTypes.func,
    style: PropTypes.object
  };

  static defaultProps = {
    card: false,
    className: '',
    fullscreen: false,
    onClick: () => {},
    style: null
  };

  render = () => {
    const containerClasses = classNames({
      [`Container u-posRelative ${this.props.className}`]: true,
      'Container--popover': true,
      'Container--card': this.props.card
    });

    return (
      <div
        onClick={this.props.onClick}
        className={containerClasses}
        style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
}
