import get from 'lodash.get';
import { sprintf } from 'sprintf-js';

import zETranslations from 'translation/ze_translations';

let currentLocale;

// The force argument is for post-render setLocale function so that
// it can override the locale if it has previously been set.
function setLocale(str = 'en-US', force = false) {
  if (!currentLocale || force) {
    currentLocale = parseLocale(str);
  }
}

function translate(key, params = {}) {
  const keyForLocale = `${key}.${currentLocale}`;
  const translation = get(zETranslations, keyForLocale);

  if (translation === undefined) {
    return params.fallback || getMissingTranslationString(key, currentLocale);
  }

  return interpolateTranslation(translation, params);
}

function getLocale() {
  return currentLocale;
}

function isRTL() {
  return !!zETranslations.rtl[currentLocale];
}

// private

function getMissingTranslationString(key, locale) {
  return `Missing translation (${locale}): ${key}`;
}

function interpolateTranslation(translation, args) {
  try {
    return sprintf(translation, args);
  } catch (_) {
    return translation;
  }
}

function regulateLocaleStringCase(locale) {
  const dashIndex = locale.indexOf('-');

  if (dashIndex < 0) {
    return locale.toLowerCase();
  }
  return locale.substring(0, dashIndex).toLowerCase() + locale.substring(dashIndex).toUpperCase();
}

function parseLocale(str) {
  const locales = zETranslations.locales;
  const locale = regulateLocaleStringCase(str);
  const lowercaseLocale = locale.toLowerCase();
  const extractLang = (locale) => {
    return locale.substring(0, locale.indexOf('-'));
  };

  if (locales.includes(locale)) {
    return locale;
  } else if (locales.includes(lowercaseLocale)) {
    return lowercaseLocale;
  } else if (locales.includes(extractLang(locale))) {
    return extractLang(locale);
  } else if (str === 'zh') {
    return 'zh-CN';
  } else if (str === 'nb' || str === 'nn') {
    return 'no';
  } else if (str === 'tl') {
    return 'fil';
  } else {
    return 'en-US';
  }
}

export const i18n = {
  t: translate,
  setLocale,
  getLocale,
  isRTL
};
