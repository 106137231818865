import { win, location } from 'utility/globals';

export function isOnHelpCenterPage() {
  const hcPattern = /^\/hc\//;

  return ['account', 'user']
    .every(key => Object.keys(win.HelpCenter || {}).includes(key))
      && location.pathname && hcPattern.test(location.pathname);
}

export function getHelpCenterArticleId() {
  const articleSegment = location.pathname.split('articles/')[1];

  return parseInt(articleSegment);
}

export function getURLParameterByName(name) {
  const half = location.search.split(`${name}=`)[1];

  return half ? decodeURIComponent(half.split('&')[0]) : null;
}
