import React from 'react';
import ReactDOM from 'react-dom';

import { AutomaticAnswersScreen } from 'component/automaticAnswers/AutomaticAnswers';
import { AutomaticAnswersDesktop } from 'component/automaticAnswers/AutomaticAnswersDesktop';
import { AutomaticAnswersMobile } from 'component/automaticAnswers/AutomaticAnswersMobile';
import { frameFactory } from 'embed/frameFactory';
import { articleClickTracker } from 'embed/articleClickTracker/articleClickTracker';
import { automaticAnswersPersistence  } from 'service/automaticAnswersPersistence';
import { i18n } from 'service/i18n';
import { transitionFactory } from 'service/transitionFactory';
import { transport } from 'service/transport';
import { generateUserCSS } from 'utility/color';
import { isMobileBrowser } from 'utility/devices';
import { getDocumentMain } from 'utility/globals';
import {
  getURLParameterByName,
  getHelpCenterArticleId
} from 'utility/pages';

import automaticAnswersCSS from './automaticAnswers.scss';

// Anything less than 500 causes an animation bug.
const showFrameDelay = 500;
const showSolvedFrameDelay = 500;
const defaultCloseFrameDelay = 30000;

let embed = {};

let closeTimeoutId;

function create(name, config = {}) {
  let frameStyle = {
    position: 'fixed',
    bottom: 0,
    margin: 0,
    zIndex: 2147483647
  };

  if (isMobileBrowser()) {
    frameStyle = {...frameStyle,...{ left: 0 }};
  } else {
    frameStyle = {...frameStyle, ...{
      right: 0,
      marginBottom: 6,
      marginRight: 6
    }};
  }

  const transitionSet = (isMobileBrowser())
    ? transitionFactory.automaticAnswersMobile
    : transitionFactory.automaticAnswersDesktop;

  const zdColorWhite = '#fff';

  const frameParams = {
    frameStyle: frameStyle,
    css: automaticAnswersCSS.toString() + generateUserCSS(zdColorWhite),
    fullWidth: isMobileBrowser(),
    // We are not using the close button on EmbedWrapper because we need the flexibility
    // to show the close button on some screens, but not others.
    hideCloseButton: true,
    name: name,
    // Add offsetHeight to allow updateFrameSize to account for the box-shadow frame margin
    offsetHeight: (isMobileBrowser()) ? 20 : 30,
    offsetWidth: (isMobileBrowser()) ? 0 : 30,
    transitions: {
      close: transitionSet.downHide(),
      upShow: transitionSet.upShow(),
      downHide: transitionSet.downHide()
    }
  };

  const ComponentType = (isMobileBrowser()) ? AutomaticAnswersMobile : AutomaticAnswersDesktop;

  const Embed = frameFactory(
    (params) => {
      return (
        <ComponentType
          ref='rootComponent'
          solveTicket={solveTicket}
          markArticleIrrelevant={markArticleIrrelevant}
          updateFrameSize={params.updateFrameSize}
          mobile={isMobileBrowser()}
          closeFrame={closeFrame}
          closeFrameAfterDelay={closeFrameAfterDelay}
          initialScreen={getInitialScreen()}
        />
      );
    },
    frameParams
  );

  const position = (isMobileBrowser()) ? 'none' : 'right';

  Object.assign(embed, {
    component: <Embed visible={false} position={position} />,
    config
  });
}

function get() {
  return embed;
}

function render() {
  const element = getDocumentMain().appendChild(document.createElement('div'));

  // ReactDOM.render does not necessarily return a value anymore
  // https://reactjs.org/blog/2017/09/26/react-v16.0.html#breaking-changes
  embed.instance = ReactDOM.render(embed.component, element);
}

function postRender() {
  const articleId = getHelpCenterArticleId();

  if (Number.isNaN(articleId)) return;

  // When either the GR or AR tracking parameters are present, we track the click and don't show the popup.
  if (articleClickTracker.isGRClickTracking() || articleClickTracker.isARClickTracking()) {
    articleClickTracker.trackClick(embed, articleId);
    return;
  }

  const authToken = automaticAnswersPersistence.getContext();

  if (!authToken) return;

  fetchTicket(authToken, articleId);
}

function closeFrame() {
  if (closeTimeoutId) {
    closeTimeoutId = clearTimeout(closeTimeoutId);
  }
  embed.instance.close({});
}

function closeFrameAfterDelay(closeFrameDelay = defaultCloseFrameDelay) {
  closeTimeoutId = setTimeout(closeFrame, closeFrameDelay);
}

function fetchTicket(authToken, articleId) {
  const fetchTicketDone = (res) => {
    const deflectionStatus = res.body.deflection_status;
    const solvedUrlParameter = Number(getURLParameterByName('solved'));

    if (deflectionStatus === 'solved') {
      if (solvedUrlParameter) {
        embed.instance.getRootComponent().ticketClosed();
        return setTimeout(() => embed.instance.show({ transition: 'upShow' }), showSolvedFrameDelay);
      } else {
        return;
      }
    }
    setTimeout(() => embed.instance.show({ transition: 'upShow' }), showFrameDelay);
  };

  const payload = {
    path: '/api/v2/rapid_resolve/fetch',
    queryParams: {
      'auth_token': authToken,
      source: 'embed',
      mobile: isMobileBrowser(),
      'article_id': articleId
    },
    method: 'get',
    callbacks: {
      done: fetchTicketDone,
      fail: () => embed.instance.hide()
    }
  };

  transport.automaticAnswersApiRequest(payload);
}

function solveTicket(authToken, articleId, callbacks) {
  const payload = {
    path: '/api/v2/rapid_resolve/solve',
    queryParams: {
      source: 'embed',
      mobile: isMobileBrowser()
    },
    method: 'post',
    callbacks: callbacks
  };
  const formData = {
    'auth_token' : authToken,
    'article_id' : articleId,
    'locale': i18n.getLocale()
  };

  transport.automaticAnswersApiRequest(payload, formData);
}

function markArticleIrrelevant(authToken, articleId, reason, callbacks) {
  const payload = {
    path: '/api/v2/rapid_resolve/article/reject',
    queryParams: {
      source: 'embed',
      mobile: isMobileBrowser()
    },
    method: 'post',
    callbacks: callbacks
  };
  const formData = {
    'auth_token' : authToken,
    'article_id' : articleId,
    'reason' : reason,
    'locale': i18n.getLocale()
  };

  transport.automaticAnswersApiRequest(payload, formData);
}

function getInitialScreen() {
  return (parseInt(getURLParameterByName('article_feedback'))
    ? AutomaticAnswersScreen.markAsIrrelevant
    : undefined);
}

export const automaticAnswers = {
  create,
  get,
  render,
  postRender,
  closeFrame,
  closeFrameAfterDelay,
  markArticleIrrelevant,
  fetchTicket,
  getInitialScreen,
  embed
};
