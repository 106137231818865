import superagent from 'superagent';

const isFunction = t => typeof(t) === 'function';

function automaticAnswersApiRequest(payload, formData = {}) {
  // TODO: Replace superagent with `fetch` so we can at least mock it using `nock`
  superagent(payload.method.toUpperCase(), payload.path)
    // superAgent sets type('json') by default, which breaks CORS.
    // setting 'form-data' results in 'Content-type: www-url-form-encoded' to prevent a preflight OPTIONS request.
    .query(payload.queryParams)
    .type('form-data')
    .send(formData)
    .end((err, res) => {
      if (err) {
        if (isFunction(payload.callbacks.fail)) {
          payload.callbacks.fail(err, res);
        }
      } else {
        if (isFunction(payload.callbacks.done)) {
          payload.callbacks.done(res);
        }
      }
    });
}

export const transport = {
  automaticAnswersApiRequest
};
