import React from 'react';

import { Button } from 'component/button/Button';
import { Container } from 'component/container/Container';
import { Icon } from 'component/Icon';
import { i18n } from 'service/i18n';
import { AutomaticAnswers } from 'component/automaticAnswers/AutomaticAnswers';

export class AutomaticAnswersDesktop extends AutomaticAnswers {
  renderTicketContent = () => {
    return (
      <div>
        {this.renderSolveQuestion()}
        {this.renderErrorMessage()}
        {this.renderButtons()}
      </div>
    );
  }

  renderSolveQuestion = () => {
    const borderStyles = (!this.state.errorMessage) ? 'u-borderBottom' : '';
    const messageClasses = [
      'AutomaticAnswersDesktop-message',
      'AutomaticAnswersDesktop-solve',
      'u-paddingBM',
      'u-marginBN',
      borderStyles
    ].join(' ');

    const solveQuestion = i18n.t('embeddable_framework.automaticAnswers.desktop.solve.question', {
      fallback: 'Does this article answer your question?'
    });
    const solveQuestionSubtext = i18n.t('embeddable_framework.automaticAnswers.desktop.solve.subtext_v3', {
      fallback: 'If it does, we can close your recent request'
    });

    return (
      <p className={messageClasses}>
        <strong>{solveQuestion}</strong>
        <p className={'u-paddingTT'}>{solveQuestionSubtext}</p>
      </p>
    );
  }

  renderErrorMessage = (classes = '') => {
    const visibilityStyles = !this.state.errorMessage ? 'u-isHidden' : '';
    const errorClasses = `Error ${visibilityStyles} ${classes}`;

    return (
      <p className={errorClasses}>
        {this.state.errorMessage}
      </p>
    );
  }

  renderButtons = () => {
    const submittingLabel = i18n.t('answer_bot_embeddable.button.submitting');
    const ctaLabel = i18n.t('embeddable_framework.automaticAnswers.button.solve_v2', {
      fallback: 'Yes, close my request'
    });
    const noLabel = i18n.t('embeddable_framework.automaticAnswers.desktop.solve.no', {
      fallback: 'No'
    });

    return (
      <div className='AutomaticAnswersDesktop-footer u-flex u-flexJustifyEnd u-gapS u-marginTM'>
        <Button
          testId="solveTicketButton"
          className='AutomaticAnswersBtn AutomaticAnswersBtn--cta u-Anim-all--fast'
          disabled={this.state.isSubmitting}
          onClick={(e) => this.handleSolveTicket(e)}
          onTouchStartDisabled={true}
          label={(this.state.isSubmitting) ? submittingLabel : ctaLabel} />

        <Button className='AutomaticAnswersBtn AutomaticAnswersBtn--no Anim-all--fast'
          disabled={this.state.isSubmitting}
          onClick={(e) => this.goToMarkAsIrrelevant(e)}
          onTouchStartDisabled={true}
          primary={false}
          label={noLabel} />
      </div>
    );
  }

  renderIrrelevantContent = () => {
    const irrelevantQuestion = i18n.t('embeddable_framework.automaticAnswers.desktop.irrelevant.question_v3', {
      fallback: 'Please tell us why:'
    });

    return (
      <div className={'AutomaticAnswersDesktop-message u-textCenter u-textSizeSml'}>
        <strong className="u-marginBS u-inlineBlock">{irrelevantQuestion}</strong>
        {this.renderErrorMessage('u-marginTT u-marginBS')}
        {this.renderIrrelevantOptions()}
      </div>
    );
  }

  renderIrrelevantOptions = () => {
    const classNames = 'AutomaticAnswersBtn c-btn--fullWidth u-marginVT Anim-all--fast';

    return [
      this.irrelevantOption(AutomaticAnswers.notRelated, classNames),
      this.irrelevantOption(AutomaticAnswers.relatedButNotAnswered, classNames)
    ];
  }

  renderSuccessContent = () => {
    const successMessage = i18n.t('embeddable_framework.automaticAnswers.desktop.solve.closed', {
      fallback: 'Nice! Your request has been closed'
    });

    return (
      <p role='status' className={'AutomaticAnswersDesktop-message u-textCenter u-marginVL'}>
        <Icon type='Icon--circleTick-large' className='u-paddingAN u-isSuccessful u-paddingBS' />
        <span>{successMessage}</span>
      </p>
    );
  }

  renderThanksForFeedbackContent = () => {
    const feedbackMessage = i18n.t('embeddable_framework.automaticAnswers.desktop.irrelevant.thanks_for_feedback_v2', {
      fallback: 'Thanks for your feedback'
    });

    return (
      <p role='status' className={'AutomaticAnswersDesktop-message u-textCenter u-marginVL'}>
        <Icon type='Icon--circleTick-large' className='u-paddingAN u-isSuccessful u-paddingBS'/>
        <span>{feedbackMessage}</span>
      </p>
    );
  }

  render = () => {
    const closeButtonAriaLabel = i18n.t('embeddable_framework.automaticAnswers.button.close', {
      fallback: 'Close dialog'
    });

    const closeButton = this.showCloseButton() ? (
      <Icon
        type="Icon--close"
        onClick={() => this.handleDismissalContext()}
        isButton={true}
        aria-label={closeButtonAriaLabel}
      />
    ) : null;

    return (
      <Container card={true} className='AutomaticAnswersDesktop u-paddingHL'>
        {closeButton}
        <div className='Container-content u-paddingBM'>
          {this.renderContent()}
        </div>
      </Container>
    );
  }
}
