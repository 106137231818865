const win = window.parent && window.parent.Zendesk ? window.parent : window;
const document = win.document;
const navigator = win.navigator;
const location = win.location;
const referrer = document.referrer;

function getDocumentHost() {
  return document.body || document.documentElement;
}

function getDocumentMain() {
  return document.querySelector('[role="main"]') || document.documentElement;
}

export { win, document, navigator, location, getDocumentHost, getDocumentMain, referrer };
