import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import iconCircleTickLarge from 'icons/widget-icon_circle_tick_large.svg';
import iconCircleTickSmall from 'icons/widget-icon_circle_tick_small.svg';
import iconClose from 'icons/widget-icon_close.svg';

import { isMobileBrowser } from 'utility/devices';

const icons = {
  'Icon--circleTick-large': iconCircleTickLarge,
  'Icon--circleTick-small': iconCircleTickSmall,
  'Icon--close': iconClose
};

export class Icon extends Component {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.string,
    isButton: PropTypes.bool
  };

  static defaultProps = {
    className: '',
    isMobile: isMobileBrowser(),
    onClick: () => {},
    type: '',
    isButton: false
  };

  render = () => {
    const icon = icons[this.props.type];
    const iconClasses = classNames({
      [`Icon ${this.props.type} ${this.props.className}`]: true,
      'is-mobile': this.props.isMobile
    });

    if (this.props.isButton) {
      return (
        <button
          onClick={this.props.onClick}
          className={iconClasses}
          aria-label='Close button'
          type="button"
          dangerouslySetInnerHTML={{ __html: icon }}
        />
      );
    } else {
      return (
        <span
          onClick={this.props.onClick}
          className={iconClasses}
          dangerouslySetInnerHTML={{ __html: icon }}
        />
      );
    }
  }
}
