const applyHiddenState = (frameHeight, isTop = false) => {
  let topPosition = {};
  const safetyPadding = 50;

  if (frameHeight > 0 && isTop) {
    topPosition = { top: `-${frameHeight + safetyPadding}px` };
  }

  return {...hiddenState, ...topPosition};
};

const defaults = (...args) =>
  args.reverse().reduce((acc, obj) => ({ ...acc, ...obj }), {});

const transitionMaker = (defaultStartParams, defaultEndParams) => {
  return (startParams, endParams) => {
    return {
      start: defaults({}, startParams, defaultStartParams),
      end: defaults({}, endParams, defaultEndParams)
    };
  };
};

const positionWithOffset = (positionStr) => {
  let position = parseInt(positionStr);

  return `${position}px`;
};

const transitionProperties = (...props) => {
  return `opacity, width, height, ${props}`;
};

const hiddenState = {
  transitionProperty: 'none',
  transitionDuration: '0',
  transitionTimingFunction: 'unset',
  opacity: 0,
  top: '-9999px'
};

const transitionFactory = {
  automaticAnswersMobile: {
    upShow: transitionMaker(
      {
        transitionProperty: 'none',
        transitionDuration: '0',
        transitionTimingFunction: 'unset',
        opacity: 1,
        bottom: '-150px'
      },
      {
        transitionProperty: transitionProperties('bottom'),
        transitionDuration: '400ms',
        transitionTimingFunction: 'ease-out',
        opacity: 1,
        bottom: 0
      }
    ),
    downHide: transitionMaker({},
      {
        transitionProperty: transitionProperties('bottom'),
        transitionDuration: '300ms',
        transitionTimingFunction: 'ease-in',
        opacity: 0,
        bottom: 0
      }
    )
  },
  automaticAnswersDesktop: {
    upShow: transitionMaker(
      {
        transitionProperty: 'none',
        transitionDuration: '0',
        transitionTimingFunction: 'unset',
        opacity: 0,
        bottom: '-200px'
      },
      {
        transitionProperty: transitionProperties('bottom'),
        transitionDuration: '500ms',
        transitionTimingFunction: 'ease-out',
        opacity: 1,
        bottom: 0
      }
    ),
    downHide: transitionMaker({},
      {
        transitionProperty: transitionProperties('bottom'),
        transitionDuration: '300ms',
        transitionTimingFunction: 'ease-in',
        opacity: 0,
        bottom: '-200px'
      }
    )
  },
  webWidget: {
    downHide: transitionMaker(
      {
        transitionProperty: 'none',
        transitionDuration: '0',
        transitionTimingFunction: 'unset',
        opacity: 1,
        bottom: positionWithOffset(400)
      },
      {
        transitionProperty: transitionProperties('bottom'),
        transitionDuration: '300ms',
        transitionTimingFunction: 'ease-out',
        opacity: 0,
        bottom: positionWithOffset(-30)
      }
    ),
    downShow: transitionMaker(
      {
        transitionProperty: 'none',
        transitionDuration: '0',
        transitionTimingFunction: 'unset',
        opacity: 0,
        top: positionWithOffset(-30)
      },
      {
        transitionProperty: transitionProperties('top'),
        transitionDuration: '300ms',
        transitionTimingFunction: 'ease-out',
        opacity: 1,
        top: positionWithOffset(0)
      }
    )
  },
  hiddenState: applyHiddenState
};

export {
  applyHiddenState,
  transitionMaker,
  positionWithOffset,
  transitionFactory
};
